.WordList ul {
    column-count: 5;
}
@media only screen and (max-width: 1200px) {
    .WordList ul {
        column-count: 4;
    }
}
@media only screen and (max-width: 700px) {
    .WordList ul {
        column-count: 3;
    }
}

.WordList ul {
    list-style: none;
    text-align: left;
}
.WordList ul li {
    cursor: pointer;
}
.WordList ul li:hover,
.WordList ul li.current-word {
    text-decoration: underline;
}

.WordListControl {
    text-align: right;
}
@media only screen and (max-width: 1000px) {
    .WordListControl {
        text-align: center;
    }
}
.WordListControl > * {
    margin: 20px 5px;
}
.WordDefinition {
    margin: 20px auto;
    text-align: center;
    max-width: 80%;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    width: 100%;
    display: block;

    padding: 15px;
    border-radius: 15px;
}

.sort-title {
    font-size: 13px;
    text-transform: uppercase;
}
