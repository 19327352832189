.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  /* Allow some full page padding */
  padding: 0 2%;
}

.App-link {
  color: #61dafb;
}

.split-display {
    flex-grow: 1;
    width: 100%;
    display: flex;
    column-gap: 20px;
}
.board-half {
    flex-shrink: 0;
}
.word-half {
    flex-grow: 1;
}

@media only screen and (max-width: 1000px) {
    .split-display {
        flex-direction: column;
    }
}

button {
    background: rgba(0,0,0,0.5);
    border: 1px solid rgb(100,100,100);
    color: white;
    padding: 5px 8px;
    transition: all 0.3s;
}
button:hover {
    border: 1px solid rgb(10,10,10);
}

.button-bar {
    margin-top: 20px;
    margin-bottom: 20px;
}

.header-puzzle {
    transition: transform .7s ease-in-out;
    transform: rotate(10deg);
}

.boggle-title {
    flex-grow: 0;
}

.boggle-title:hover .header-puzzle {
    transform: rotate(0deg);
}

.docs {
    margin: 0 auto;
    max-width: 600px;
    padding: 30px 2%;
}
.docs a {
    color: black;
    font-weight: bold;
}
.docs a:hover {
    color: grey;
}
.docs h2 {
    margin-top: 40px;
}

.docs--credits {
    border-top: 1px solid #DDD;
}
