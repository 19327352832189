.boggle-board-table {
    border: 1px solid white;
    margin: 0 auto;
    border-radius: 15px;
}
.boggle-board-table td {
    padding: 5px;
    font: monospace;
}

.letter-input {
    font-size: 2em;
    background: transparent;
    color: white;
    border: 0;

    /* Keep this? */
    /* Hide dropdown arrow on select for cleaner board look */
    -webkit-appearance: none;

    /* hack for centering text in select */
    padding-left: 31%;
}
.letter-input-qu {
    padding-left: 0;
}
.letter-input option {
    color: black;
}

.highlight {
    border: 2px rgba(193, 66, 66, 0.3) solid;
}

