.debug-toggle {
    position: fixed;
    bottom: 5px;
    right: 0;
    z-index: 100;

    background: transparent;
    border: none;
}
.debug-bar {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    background: black;
    color: white;

    font-size: 12px;
}
.debug-bar.debug-bar--show {
    display: block;
}
.debug-bar span {
    margin: 0 15px;
}
.debug-bar button {
    background: none;
    padding: 1px 2px;
    border: 1px solid white;
    color: white;
    margin: 2px;
}
